<template>
    <div @click.passive="deleteAllFilters" v-if="showResetAll()===true"
         class="btn-default-gray">
        <span class="whitespace-nowrap text-xs  mx-1"><a :href="route('search.page')" @click.prevent class="!no-underline">
            Reset filters
        <CustomSvg :name="'chevron-x'" color="red" class="text-gray-600 mr-1 dark:text-white  "
                   :customClass="'fill-gray-500 w-3  !float-right ml-1 p-1 mt-0.5 '"></CustomSvg>
        </a></span>
    </div>
</template>

<script>

import JetNavLink from '@/Jetstream/NavLink.vue'
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue'


export default {

    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        JetNavLink,
        CustomSvg
    },
    props: {
        website: Object,
        max: Number,
        min: Number,
    },

    data() {
        return {

        }
    },
    methods: {
        showResetAll() {
            if(this.website.search.max_price/100!==this.max || this.website.search.min_price/100!==this.min){
                return true;
            }
            if (this.website.search.q !== undefined && this.website.search.q !== '') {
                //  return true;
            }
            for (var k in this.website.search.selected_filters) {
                if (k !== 'categories' && Object.keys(this.website.search.selected_filters[k]).length) {
                    return true;
                }
            }
        },

        deleteAllFilters() {
            let filter = this.website.search.selected_filters;
            Object.keys(this.website.search.selected_filters).forEach(function (group) {
                if (group !== 'categories') {
                    filter[group] = new Object([]);
                }
            });
            this.website.filters = filter;
            this.onRemoveFilters();
        },
        onRemoveFilters(event) {
            this.$emit('website_filter_reset', this.website.search);
        },
    },
    computed: {}
}
</script>
